.active-plane{ background: #0b33b717;}

.btn-details-plan{background: none;
    padding: 5px 10px 5px 10px;
    font-size: 14px;
    border-radius: 5px;
    color: #000;
    cursor: pointer;
    border: 1px solid #000;}
.btn-select-plan{
    background: none;
    padding: 5px 10px 5px 10px;
    font-size: 14px;
    border-radius: 5px;
    color: #000;
    cursor: pointer;
    border: 1px solid rgb(0, 0, 0);}
    .btn-upgrade-plan{background: #467cb3;
    padding: 10px 15px;
    font-size: 14px;
    border-radius: 5px;
    color: #fff;
    cursor: pointer; border: none;}
    .plan-table h3{ font-size: 18px;color: #000;}
    .btn-close-plan{background: #666;
    padding: 10px 15px;
    font-size: 14px;
    border-radius: 5px;
    color: #fff;
    cursor: pointer; border: none;}
    .no-border td{ border: none;}

.activecheck{
    color: #000000;
}
    /* new */

    .snip1214 {
  font-family: 'Plus Jakarta Sans', sans-serif;
  color: #000000;
  text-align: center;
  font-size: 16px;
  width: 100%;
  max-width: 1000px;
  margin: 40px 0;
}
.snip1214 .plan {
  margin: 0;
  width: 25%;
  position: relative;
  float: left;
  background-color: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
}
.snip1214 * {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.snip1214 header {
  position: relative;
}
.snip1214 .plan-title {
  position: relative;
  top: 0;
  font-weight: 800;
  padding: 5px 15px;
  margin: 0 auto;
  /* -webkit-transform: translateY(-50%);
  transform: translateY(-50%); */
  margin: 0;
  display: inline-block;
  /* background-color: #222f3d; */
  color: #222f3d;
  text-transform: uppercase;
  margin-top: 15px;
    font-weight: bold !important;
}
.snip1214 .plan-cost {
  padding: 0px 10px 20px;
}
.snip1214 .plan-price {
  
  font-weight: 800;
  font-size: 1.4em;
  color: #34495e;
}
.snip1214 .plan-type {
  opacity: 0.6;
}
.snip1214 .plan-features {
  padding: 0;
  margin: 0;
  text-align: center;
  list-style: outside none none;
  font-size: 0.8em;
}
.snip1214 .plan-features li {
  border-top: 1px solid #d2d7e2;
  padding: 10px 5%;
}
.snip1214 .plan-features li:nth-child(even) {
  background: rgba(0, 0, 0, 0.08);
}
.snip1214 .plan-features i {
  margin-right: 8px;
  opacity: 0.4;
}
.snip1214 .plan-select {
  /* border-top: 1px solid #d2d7e2; */
  padding: 10px 10px 0;
  margin-bottom: 15px;
}
.snip1214 .plan-select a {
  background-color: #3f5f81;
  color: #ffffff;
  text-decoration: none;
  padding: 0.5em 1em;
  /* -webkit-transform: translateY(50%);
  transform: translateY(50%); */
  font-weight: 400;
  text-transform: uppercase;
  display: inline-block;
  border-radius: 6px;font-size: 12px;
  width: 80%;
    margin-bottom: 10px;
}
.snip1214 .plan-select a:hover {
  background-color: #46627f;
}
.snip1214 .featured {
  margin-top: -10px;
  background-color: #467cb3;
  color: #ffffff;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
  z-index: 1;
}
.snip1214 .featured .plan-title,
.snip1214 .featured .plan-price {
  color: #ffffff;
}
.snip1214 .featured .plan-cost {
  padding: 10px 10px 20px;
}
.snip1214 .featured .plan-features li {
  border-top: 1px solid rgba(255, 255, 255, 0.4);
}
.snip1214 .featured .plan-select {
  padding: 20px 10px 0;
  border-top: 1px solid rgba(255, 255, 255, 0.4);
}
@media only screen and (max-width: 767px) {
  .snip1214 .plan {
    width: 50%;
  }
  .snip1214 .plan-title,
  .snip1214 .plan-select a {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  .snip1214 .plan-cost,
  .snip1214 .featured .plan-cost {
    padding: 20px 10px 20px;
  }
  .snip1214 .plan-select,
  .snip1214 .featured .plan-select {
    padding: 10px 10px 10px;
  }
  .snip1214 .featured {
    margin-top: 0;
  }
}
@media only screen and (max-width: 440px) {
  .snip1214 .plan {
    width: 100%;
  }
}


.plan h3{    font-size: 16px;
   }

    .logout-body{padding-bottom: 20px !important;
    display: inline-block;}

    .toggle {
  margin-top: 2rem;
  color: hsl(234, 14%, 74%);
  display: flex;
  align-items: center;

    margin: 0 auto;
    width: 212px;
   
}
.toggle-btn {
  margin: 0 1rem;
}
.checkbox {
  display: none;
}

.sub {
  background: #4e73df;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  /* height: 25px;
  width: 50px; */
  height: 1.6rem;
  width: 3.3rem;
  border-radius: 1.6rem;
  padding: 0.3rem;
}
.circle {
  background-color: #fff;
  height: 1.4rem;
  width: 1.4rem;
  border-radius: 50%;
}
.checkbox:checked + .sub {
  justify-content: flex-end;
}

.cards {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.snip1214 .plan-select a.plan-details{background-color: #3367a1;}


.pricingDv .pricing-nav-one {
  margin: 15px 0 20px;
}

.pricing-nav-one {

  border: none;
}

.pricing-nav-one .nav-item {
  margin: 0;
}

.nav-link {
  display: block;}


  /* .pricing-nav-one .nav-item .nav-link.active {
    background:#4e73df;
    color: #fff;
} */
/* .pricing-nav-one .nav-item:nth-child(1) .nav-link {
    border-right: none;
    border-radius: 3px 0 0 3px;
} */
/* .pricing-nav-one .nav-item .nav-link {
    font-family: 'Plus Jakarta Sans', sans-serif;
    line-height: 44px;
    border: 2px solid#4e73df;
    padding: 0 15px;
    width: 172px;
    text-align: center;
    color:#4e73df;
    transition: all 0.3s ease-in-out;
} */

/* .pricing-nav-one .nav-item:nth-child(2) .nav-link {
  border-left: none;
  border-radius: 0 3px 3px 0;
} */
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  border-color: transparent;
  background: #255C6C !important;
  color: #fff;
}
.pr-table-wrapper {
  padding: 15px 10px;
}

.pr-table-wrapper {
  /* background: #fff;
  box-shadow: 0 -10px 40px rgb(60 67 113 / 5%);
  padding: 35px 0 25px; */
  text-align: center;
  /* transition: all 0.3s ease-in-out;
  border: 2px solid #ddd; */
}
.pr-table-wrapper.standard{     border-color: #4e73df;
  margin-right: -1px;
  background: #f2f2ff;
  position: relative;
  z-index: 9;}

  .pr-table-wrapper {
    padding: 30px 20px;
}

.pr-table-wrapper .pack-name {
  font-size: 18px;
  text-transform: capitalize;
  color: #202020;
  letter-spacing: normal;
  font-weight: 500;
  margin: 0px 0 5px;
}
.pack-rec.font-rubik.mb-5 {
  font-weight: 400;
}

.pr-table-wrapper .icon {
  margin: 8px auto 8px;
  height: 75px;
}
.pr-table-wrapper .bill-cycle {
  font-size: 17px;
  color: #161616;
  font-weight: 400;
}
.pack-rec {
  text-align: center;
  margin: 0 0 10px;
}
.bill-cycle span {
  font-size: 24px;
  font-weight: 700;
  color: #161616;
  display: block;
}

.theme-btn-four {
  background: #255C6C !important;
  color: #fff;
  font-weight: 600;
  border-radius: 30px;
  font-size: 12px;
  padding: 8px 15px;
  margin: 10px auto 0;
  display: block;
  width: 170px;
}
.textHd {
  color: #000; font-weight: 600;
}


@media (min-width: 992px){
  .modal-lg, .modal-xl {
      max-width: 900px;
  }
}

.plancolor {
  color: #000;
}



.StripeElement {
  display: block;
  margin: 10px 0 20px 0;
  max-width: 500px;
  padding: 10px 14px;
  font-size: 1em;
  font-family: "Source Code Pro", monospace;
  box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px,
    rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
  border: 0;
  outline: 0;
  border-radius: 4px;
  background: white;
}

input::placeholder {
  color: #aab7c4;
}


.StripeElement--focus {
  box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px,
    rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
}

.StripeElement.IdealBankElement,
.StripeElement.FpxBankElement,
.StripeElement.PaymentRequestButton {
  padding: 0;
}

.StripeElement.PaymentRequestButton {
  height: 40px;
}

.element-css{
  display: flex;
  flex-direction: column;
}