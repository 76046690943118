/* nav {
    cursor: pointer;
} */

ul,
li {
    margin: 0;
    padding: 0;
}

.main {
    position: absolute;
    z-index: 1;
    margin-top: 0px !important;

}

.main li {
    list-style: none;
    /* background: rgb(243, 243, 243); */
    background: #fff;
    width: 175px;
    padding: 0 5px;
    border: 1px solid rgb(222, 217, 217);
    height: 40px;

    line-height: 40px;

    -webkit-transition: all .5s ease-in-out;
    font-weight: none;
}

.main li:nth-child(odd) {

    -webkit-transform-origin: top;
    -webkit-transform: perspective(350px) rotateX(-90deg);
}

.main li:nth-child(even) {
    margin-top: -65px;
    -webkit-transform-origin: bottom;
    -webkit-transform: perspective(350px) rotateX(90deg);
}

/*.main {
  
  -webkit-transition: all .5s ease-in-out;
  -webkit-transform-origin: 50% 0%;
  -webkit-transform: perspective(350px) rotateX(-90deg);
}

.hov:hover .main {
  
  -webkit-transform-origin: top;
  -webkit-transform: perspective(350px) rotateX(0deg);
}*/

.hov:hover li:nth-child(odd) {
    -webkit-transform-origin: top;
    -webkit-transform: perspective(350px) rotateX(0deg);
    /* margin-top: 15px; */
}

.hov:hover li:nth-child(even) {
    -webkit-transform-origin: bottom;
    -webkit-transform: perspective(350px) rotateX(0deg);
    margin-top: 0;
}

.main li:first-child {
    margin-top: 2px;
}

.hov {
    position: relative;
    height: 40px;
    width: 200px;
    /* background: green; */
    color: #5a5c69;
    font-size: 13px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-weight: bold;
    text-align: center;
    line-height: 40px;
    list-style: none;
    z-index: 2;

}


.no-profile-img {
    height: 40px;
    border-radius: 50%;
}

.Profile-link:hover {
    text-decoration: none;
}

.btn-upload-upgrade {
    cursor: pointer;
    width: 125px;
    margin: auto;
    /* margin-top: 20px; */
    border: none;
    background: #f6c23ecf;
    padding: 5px 10px 5px 10px;
    font-size: 12px;
    border-radius: 15px;
    color: #000;
}

.capitalizetrial{
    text-transform: capitalize;
}