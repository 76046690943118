.logout-body {
    /* margin: auto; */
    width: 100%;
    /* text-align: center; */
}

.model-content {
    /* margin: auto; */
}

.logout-icon {
    font-size: 30px;
    color: #3498db;
}

.logout-message {
    margin-left: 10px;
}

.btn-logout {
    float: right;
    background: #3dbc93;
    color: #fff;
}

.btn-logout:hover {
    color: #fff;
    background-color: #07a674;
}

.btn-logout-cancel {
    float: right;
    background: #666;
    color: #fff;
}

.btn-logout-cancel:hover {
    float: right;
    background: #4c4949;
    color: #fff;
}