.side-pdf-list {
  overflow-y: scroll;
  overflow-x: hidden;
}


.leftcontainer{
      height: 75vh;
    overflow: scroll;
    overflow-x: hidden;
}
/* width */
.leftcontainer::-webkit-scrollbar {
  width: 8px;
}

/* Track */
.leftcontainer::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.leftcontainer::-webkit-scrollbar-thumb {
  background: rgb(253, 253, 253);
  border-radius: 10px;
}

/* Handle on hover */
.leftcontainer::-webkit-scrollbar-thumb:hover {
  background: #555;
}


.desc-container .row {
  margin: 0%;
  padding: 4px 10px;
}

.select-role {
  width: 100%;
    font-weight: bold;
}

.select-required {
  width: 100%;
  padding: 6px 0;
}

.drop-input{
    
    background: transparent;
    z-Index: 1;
    position: fixed;
    margin: -3px;
    border: 1px solid black;
    height: 16px;
    width: 120px;
    padding: 5px;
  
}

.custom-width-pdf{width: 770px !important;}
.fieldscss ul{ list-style: none; padding-left: 0;}
.fieldscss h5{ font-size: 26px; margin: 20px 15px;color: #000;}
.fieldscss ul li{padding: 14px 0;
    border-bottom: 1px solid #ccc; color: #000000ad;}