.pdf-wrapper-pages {
  height: 80vh;
  overflow-y: scroll;
}
.pdf-wrapper-pages::-webkit-scrollbar {
  width: 4px;
}

/* Track */
.pdf-wrapper-pages::-webkit-scrollbar-track {
  background: #c9cedb;
}

/* Handle */
.pdf-wrapper-pages::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
.pdf-wrapper-pages::-moz-scrollbar-thumb:hover {
  background: #555;
}

.pdf-wrapper-pages::-moz-scrollbar-track {
  background: #c9cedb;
}

/* Handle */
.pdf-wrapper-pages::-moz-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
.pdf-wrapper-pages::-moz-scrollbar-thumb:hover {
  background: #555;
}

.canvastemplatepages {
  overflow-x: hidden;
  width: 100%;
}

.canvastemplatepages canvas {
  width: 100% !important;
}

.canvastemplatepages .react-pdf__Page__textContent {
  width: 100% !important;
}

.accordion-item h5.accordion-header button.accordion-button {
  width: 100%;
  text-align: left;
  border: 1px solid #00000020;
  padding: 10px;
  color: #2f2e2eed;
  background-color: #fff;
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
  border-radius: 4px;
  font-size: 16px;
  /* background: url(
   ../../assets/images/211687_down_arrow_icon.svg
  ) no-repeat  98% 12px ;
   background-size: 20px; */
  /* box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%); */
}
.accordion-item h5.accordion-header button.accordion-button:focus {
  outline: none;
  box-shadow: none;
  transition: transform 0.2s ease-in-out;
  /* background: url(
   ../../assets/images/211687_down_arrow_icon.svg
  ) no-repeat 98% 12px ;
   background-size: 20px; */
}

.check-container-font {
  font-size: 18px;
}

.accordion-header-margin{
  margin: 20px 10px !important;
}


.rightcontainer{
      height: 75vh;
    overflow: scroll;
    overflow-x: hidden;
}
/* width */
.rightcontainer::-webkit-scrollbar {
  width: 8px;
}

/* Track */
.rightcontainer::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.rightcontainer::-webkit-scrollbar-thumb {
  background: rgb(253, 253, 253);
  border-radius: 10px;
}

/* Handle on hover */
.rightcontainer::-webkit-scrollbar-thumb:hover {
  background: #555;
}