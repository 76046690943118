.canvastemplate{
    width: 1020px !important;
    /* margin: 0 auto; */
    /* position: absolute !important; */
    overflow: hidden;
}

.canvastemplate canvas {
    width: 1020px !important;
  /* width: 1020px !important;
  margin: ; */
  /* zoom: 200% !important; */
}

.canvastemplate .react-pdf__Page__textContent {
    width: 1020px !important;
  /* zoom: 200% !important; */
  /* width:1020px !important; */
}
