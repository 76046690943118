@font-face {
    font-family: 'Gilroy-Light';
    src: url('../fonts/Gilroy-Light.otf') format('opentype'),
        url('../fonts/Gilroy-Light.ttf') format('truetype');
}


@font-face {
    font-family: 'Gilroy-medium';
    src: url('../fonts/Gilroy-Medium.ttf') format('truetype'),

}


@font-face {
    font-family: 'Gilroy-Regular';
    src: url('../fonts/Gilroy-Regular.ttf') format('truetype'),

}


@font-face {
    font-family: 'Gilroy-SemiBold';
    src: url('../fonts/Gilroy-SemiBold.ttf') format('truetype'),

}



@font-face {
    font-family: 'Gilroy-ExtraBold';
    src: url('../fonts/Gilroy-ExtraBold.otf') format('opentype'),
        url('../fonts/Gilroy-ExtraBold.ttf') format('truetype'),

}


.table-action-button {
    color: #000 !important;
    border: none !important;
    background: none !important;
    padding: 0px !important;
}

.table-action-button:focus {
    outline: none !important;
    box-shadow: none !important;
}

thead {
    font-size: 14px;
}

tbody {
    font-size: 13px;
}

.search-bar {
    border: 1px solid #ccc;
    height: 30px;
    width: 210px;
    margin-top: 15px;
    border-radius: 5px;
}

.search-icon {
    color: #9ca3af;
    margin-left: 5px;

}

.search-box {
    border: none !important;
    color: #666 !important;
}

.search-box:focus {
    outline: none;
}

.icon-close-model {
    float: right;
    background: #efefef;
    padding: 5px 10px 5px 10px;
    font-size: 10px;
    border-radius: 15px;
    color: #000;
    cursor: pointer;
}

.icon-close-model:hover {
    background-color: #ccc;
}


.custom-spinner {
    border-top-color: #0b0b0b !important;
}

.spinner-div {
    display: flex;
    justify-content: center;
}

.ColDv {
    height: 160px;
    display: flex;
    align-items: center;
    padding: 10px 10px 10px 30px;
    /* text-align: center; */
}

.sidebar {
    /* background-color: #0069EC;
    background-image: none; */
    background-image: linear-gradient(to right bottom, #1578a2, #2b8bb6, #3d9fcb, #4eb3df, #5fc8f4) !important
}

.dashTopRow .h5 {
    font-size: 48px;
    color: #192C4D !important;
    font-weight: 500;
    margin-bottom: 20px;
    font-family: 'Gilroy-Light';
}

.ColDv a {
    font-size: 14px;
    color: #0069EC;
    font-weight: 500;
}

.ColDv .col {
    padding: 0;
}

/* .midRgtCol {
    height: 255px;
} */

.row.dashBtmRow h5,
.midRgtCol h5 {
    color: #192C4D;
    font-weight: 600;
    text-transform: capitalize;
}

.sidebar .nav-item .nav-link span {
    font-size: 15px;
}


.btn-upload-signature {
    cursor: pointer;
    width: 168px;
    margin: auto;
    /* margin-top: 20px; */
    background: #0069EC;
    padding: 5px 10px;
    font-size: 16px;
    border-radius: 15px;
    color: #fff;
    font-weight: 600;
}


.bgColor1 {
    background: linear-gradient(138deg, rgba(63, 109, 219, 1) 0%, rgba(69, 138, 243, 1) 80%) !important;
    position: relative;
    height: 190px !important;
}

.hgtDv{height: 190px;}

.bgColor1 .ColDv a,
.bgColor2 .ColDv a,
.bgColor3 .ColDv a {
    color: #fff;
    font-weight: 700;

}

.dashTopRow .bgColor1 .h5,
.dashTopRow .bgColor2 .h5,
.dashTopRow .bgColor3 .h5 {
    color: #fff !important;



}


.bgColor2 {
    background: linear-gradient(138deg, rgba(52, 174, 224, 1) 0%, rgba(95, 200, 244, 1) 80%) !important;
    position: relative;

}

/* .bgColor2:before,
.bgColor3:before,
.bgColor1:before {
    background: url(../images/fadeLogo.png) no-repeat;
   
    content: "";
    background-position: 0 0;
    position: absolute;
    top: 63%;
    right: -44px;
    width: 180px;
    height: 108px;
} */

.bgColor3 {
    background: linear-gradient(138deg, rgba(33, 85, 100, 1) 0%, rgba(65, 144, 168, 1) 80%) !important;
    position: relative;
}


.btn-upload-signature {
    background: #255C6C !important;
    padding: 8px 15px !important;
}

.boxIcon img {
    height: 30px;
}

.boxIcon {
    margin: 0 0 20px;
}

.bxTxt a {
    font-size: 14px;
}

.btnReport {
    background: #255C6C !important;
    color: #fff;
    font-weight: 600;
    border-radius: 30px;
    font-size: 12px;
    padding: 8px 25px;
    margin: 70px 0 0;
    display: inline-block;
}

.midRgtCol {
    background: url(../images/report-bg.jpg) no-repeat;
    background-position: right bottom;
    background-size: cover;
}

.midRgtCol p {
    color: #161616;
}

.btnReport:hover {
    text-decoration: none;
    color: #fff;
}

.cusTable thead tr {
    background-color: #dee8eb !important;
}

.cusTable tr:nth-child(even) {
    background-color: #f5f5f7 !important;
}

.pbtm {
    padding-bottom: 30px;
}


.btnMr {
    background: #255C6C !important;
    color: #fff;
    font-weight: 600;
    border-radius: 30px;
    font-size: 12px;
    padding: 8px 25px;
    margin: 20px 0 0;
    display: inline-block;
}



.btnMr:hover {
    color: #fff;
    text-decoration: none;
}

ul.header-tab li {
    background-color: #fff;
    color: #7b8ca8;
    border-radius: 0px;
    padding: 12px 30px;
    margin: 0;
    font-weight: 500;
    border: 1px solid #cad1dc;
    border-radius: 5px;
    font-size: 16px;
    margin: 0 8px    0;
    line-height: normal;
}

ul.header-tab li:hover,
ul.header-tab li.active-tab {
    background: #255C6C !important;
    color: #fff;
}

.basic-signature-container {
    margin: 0 !important;
}

.nav-icon-height {
    height: 16px !important;
    position: relative;
    top: 0px;
    vertical-align: middle;
}

.sidebar .nav-item .nav-link {
    display: inline-block;
    vertical-align: middle;
}

.sidebar .nav-item .nav-link span {
    font-size: 15px;
    display: inline-block;
    vertical-align: middle;
    margin-right: 5px;
}
.sidebar .nav-item .nav-link.active {
    background-color: rgba(33, 85, 100, 0.8);
}

/* body , p{ font-family: 'Gilroy-Regular' !important; font-size: 16px;} */

.cusTable tbody tr td {
    border: 0 !important;
}


.btn-primary {
    color: #fff;
    background: #255C6C !important;
    border-radius: 30px !important;
    border-color: transparent;
    padding: 8px 25px !important;
    font-weight: 600;
}


.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl {
    padding-bottom: 30px;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.current {
    background: #0069EC !important;
    color: #fff !important;
}

ul.nav.nav-tabs li {
    margin-right: 7px;
}

.nav-tabs .nav-link {
    margin-bottom: 0;

    background-color: #fff;
    color: #7b8ca8;
    border-radius: 0px;
    padding: 12px 30px;
    margin: 0;
    font-weight: 500;
    border: 1px solid #cad1dc;
    border-radius: 5px;
    font-size: 16px;
}

.nav-tabs {
    border-bottom: 0;
}

.nav-tabs .nav-link.active {
    background: #255C6C;
    color: #fff;
}


.card-header {
    padding: 0.75rem 1.25rem;
    margin-bottom: 0;
    background-color: #dee8eb;
    border-bottom: 1px solid #e3e6f0;
    color: #000;
}

.crdCont {
    background-color: #f5f5f7;
}

.card-header h6 {
    font-weight: 600;
}

.crdCont h5 {
    color: #000;
    font-weight: 400;
    font-size: 30px;
}

/* .dashboard-no-profile-img{display: none;} */

.dasgboard-field-show-fieldset {
    width: 100%;
}

.dashboard-no-profile-img {
    height: 150px !important;
    margin-top: 0 !important;
}

.cusTable tbody tr td {
    border-bottom: 0 !important;
}

.actionCont {
    text-align: center;
}

.btn-update {
    float: right;
    background: #255C6C !important;
    padding: 8px 25px!important;
    font-size: 12px!important;
    border-radius: 15px!important;
    color: #fff!important;
    cursor: pointer!important;
    font-weight: 500!important;
}

svg.svg-inline--fa.fa-pen {
    margin-right: 5px;
}

.dasgboard-field-show-fieldset {
    width: auto;
    margin-top: 40px;
}

.brandingDv h2 {
    font-size: 30px;
    color: #161616;
    text-transform: none;
    font-weight: 600;
    margin: 0 0 20px;
}

.color {
    width: 100% !important;
    height: 48px !important;
    border-radius: 5px !important;
}



.brandingDv .card-body h6 {
    font-size: 1.25rem;
    color: #192C4D;
    font-weight: 600;
    text-transform: capitalize;
    margin: 0 0 20px;
}


.brandingDv .card {
    margin: 0 0 20px;
}

.brandingDv .card-body img {
    height: 48px;
    width: auto;
}

.brandingDv .card-body {
    text-align: center;
}


.modal-body .barndModal label {
    font-size: 1.25rem;
    color: #192C4D;
    font-weight: 600;
    text-transform: capitalize;
    margin: 0;
}

.uploadedImg img {
    padding: 6px;
    background: #fff;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, .1);
    border-radius: 5px;
}

.swatch {
    width: 100%;
}

.barndModal .form-group {
    margin: 0;
}

.barndModal .card-body {
    height: 225px;
}


.barndModal .color {
    height: 125px !important;
}

.prvwBrnd h6 {
    margin: 20px 0;
    font-size: 1.25rem;
    color: #192C4D;
    font-weight: 600;
    text-transform: capitalize;
}

.sidebar .sidebar-brand {
    text-align: left;
}

.sidebar {
    background-image: linear-gradient(90deg, rgba(33, 85, 100, 1) 0%, rgba(65, 144, 168, 1) 80%) !important;
}


.btn-secondary {
    background-color: #fff;
    color: #7b8ca8;
    padding: 8px 25px;
    margin: 0;
    font-weight: 500;
    border: 1px solid #cad1dc;
    border-radius: 30px;
}

.modal-title {
    margin-bottom: 0;
    line-height: 1.5;
    color: #192C4D;
    font-weight: 600;
    text-transform: capitalize;
}

.modal-body label, label {
    font-size: 15px;
    color: #192C4D;
    font-weight: 600;
    text-transform: capitalize;
    margin: 0 0 10px;
}

.modal-body{padding: 2rem;}

h1,
h5 {
    color: #192C4D !important;
    font-weight: 600 !important;
    text-transform: capitalize;
}

.text-gray-800 {
    color: #192C4D !important;
}


.boxFx .card-container .row .row {
    margin: 0;
}

.text-right {
    text-align: right;
}

.signFld {
    border: 1px solid #d1d3e2;
    border-radius: 5px;
}


.permission-header {
    font-size: 16px;
    color: #192C4D;
    font-weight: 600;
    text-transform: capitalize;
    margin: 0 0 10px;
}

.permission-header label {
    font-size: 16px;
}

.field-show-fieldset img {
    max-width: 100%;
    height: auto !important;
    max-height: 30px;
}

a.paginate_button {
    background: #fff !important;
    border-radius: 4px;
    color: #fff !important;
    padding: 5px 15px !important;
    border: 1px solid #cad1dc !important;
    margin: 0 5px;
}

a.paginate_button:hover {
    background: #255C6C !important;
    color: #fff !important;
}

.table-responsive .dataTables_wrapper .dataTables_paginate .paginate_button.current,
.table-responsive .dataTables_wrapper .dataTables_paginate .paginate_button.current:hover {
    color: #fff !important;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.disabled,
.dataTables_wrapper .dataTables_paginate .paginate_button.disabled:hover {
    background: #cad1dc !important;
    color: #000 !important;
}

.badge{padding: 5px 8px !important;}

.badge-primary {
    color: #fff;
    background-color: #255C6C !important;
}

.badge-warning {
    color: #fff;
    background-color: #7b8ca8 !important;
}

span.btn-update svg {
    margin-right: 4px !important;
}

.navbar-nav .nav-icon-height {
    height: 14px!important;
}

img.navicn {
    width: 14px;
    margin-right: 6px;
}

.btn-primary:hover,
.btn-upload-signature:hover,
.btnReport:hover,
.btnMr:hover{background-color: #161616 !important ;}

@media(max-width:1399px) {
    .ColDv a {
        font-size: 14px;
    }
}

@media(max-width:1279px) {
    .ColDv a {
        font-size: 12px;
    }
}