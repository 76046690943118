        .pdf-wrapper{ height: 89vh; overflow-y: scroll;width: fit-content; }
.pdf-wrapper::-webkit-scrollbar {
  width: 4px;
}

/* Track */
.pdf-wrapper::-webkit-scrollbar-track {
  background: #C9CEDB; 
}
 
/* Handle */
.pdf-wrapper::-webkit-scrollbar-thumb {
  background: #888; 
}

/* Handle on hover */
.pdf-wrapper::-moz-scrollbar-thumb:hover {
  background: #555; 
}

.pdf-wrapper::-moz-scrollbar-track {
  background: #C9CEDB; 
}
 
/* Handle */
.pdf-wrapper::-moz-scrollbar-thumb {
  background: #888; 
}

/* Handle on hover */
.pdf-wrapper::-moz-scrollbar-thumb:hover {
  background: #555; 
}

.top-footer-part-setup {
  position: fixed;
    top: 0px;
    width: 100%;
    padding: 10px 10px;
    background: #fff;
    border-bottom: 2px solid #e3e6f0;
    left: 0;
    z-index: 99;
 
}
.mt-7{margin-top: 70px;}


.PSPDFKit-Text-Markup-Inline-Toolbar-Strike-Out,
.PSPDFKit-Text-Markup-Inline-Toolbar-Underline,
.PSPDFKit-Text-Markup-Inline-Toolbar-Squiggle {
	display: none;
}